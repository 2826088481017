import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { differenceInDays, endOfDay, isValid, parse, startOfDay } from 'date-fns'

export function mensageAlert(Title, mode) {
  let setup
  if (mode == 'error')
    setup = {
      icon: 'BellIcon',
      variant: 'danger',
    }
  else if (mode == 'success')
    setup = {
      icon: 'CheckIcon',
      variant: 'success',
    }
  this.$toast(
    {
      component: ToastificationContent,
      props: {
        title: Title,
        icon: setup.icon,
        variant: setup.variant,
      },
    },
    {
      position: 'top-right',
      timeout: 8000,
    },
  )
}

export const Ufs = [
  { text: 'Selecione', value: '' },
  { text: 'Acre', value: 'AC' },
  { text: 'Alagoas', value: 'AL' },
  { text: 'Amapá', value: 'AP' },
  { text: 'Amazonas', value: 'AM' },
  { text: 'Bahia', value: 'BA' },
  { text: 'Ceará', value: 'CE' },
  { text: 'Distrito Federal', value: 'DF' },
  { text: 'Espírito Santo', value: 'ES' },
  { text: 'Goiás', value: 'GO' },
  { text: 'Maranhão', value: 'MA' },
  { text: 'Mato Grosso', value: 'MT' },
  { text: 'Mato Grosso do Sul', value: 'MS' },
  { text: 'Minas Gerais', value: 'MG' },
  { text: 'Pará', value: 'PA' },
  { text: 'Paraíba', value: 'PB' },
  { text: 'Paraná', value: 'PR' },
  { text: 'Pernambuco', value: 'PE' },
  { text: 'Piauí', value: 'PI' },
  { text: 'Rio de Janeiro', value: 'RJ' },
  { text: 'Rio Grande do Norte', value: 'RN' },
  { text: 'Rio Grande do Sul', value: 'RS' },
  { text: 'Rondônia', value: 'RO' },
  { text: 'Roraima', value: 'RR' },
  { text: 'Santa Catarina', value: 'SC' },
  { text: 'São Paulo', value: 'SP' },
  { text: 'Sergipe', value: 'SE' },
  { text: 'Tocantins', value: 'TO' },
]

export function phoneMask(value) {
  const ddd = [/[0-9]/, /[0-9]/]

  if (value.length < 14) {
    return ['(', ...ddd, ')', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
  }
  return value.length > 2
    ? ['(', ...ddd, ')', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    : ['(', ...ddd, ')']
}

export function isLetter(e) {
  let char = String.fromCharCode(e.keyCode)
  if (/^[a-zA-Z\u00C0-\u017F\s]+$/.test(char)) return true
  else e.preventDefault()
}

// export function isNumber(e) {
//   let char = String.fromCharCode(e.keyCode);
//   if (/^[0-9]+$/.test(char)) return true;
//   else e.preventDefault();
// }
export function isNumber(numero) {
  return Number(numero) === numero
}

/**
 * Remove os caracteres que não sao números e retorna no tipo Number
 * @param {string} campo
 * @returns {Number}
 */
export function toNumeric(campo) {
  let numero = campo.replace(/[^\d]/g, '')
  return numero
}

export function isCPFValid(cpf) {
  if (cpf == undefined) return false
  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf == '' || cpf.length !== 11) return false
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false
  // Valida 1o digito
  var add = 0
  for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i)
  var rev = 11 - (add % 11)
  if (rev == 10 || rev == 11) rev = 0
  if (rev != parseInt(cpf.charAt(9))) return false
  // Valida 2o digito
  add = 0
  for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i)
  rev = 11 - (add % 11)
  if (rev == 10 || rev == 11) rev = 0
  if (rev != parseInt(cpf.charAt(10))) return false
  return true
}
export function validarEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(String(email).toLowerCase())
}
export function validarTelefone(telefone) {
  const re = /^[1-9]{2}(?:9[0-9]{8})$/
  return re.test(String(telefone).replace(/[^\d]/g, ''))
}
export function validarCnpj(cnpj) {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/[^\d]/g, '')
  // Verifica se o CNPJ tem 14 dígitos
  if (cnpj.length !== 14) return false
  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cnpj)) return false
  // Calcula o primeiro dígito verificador
  let sum = 0
  let peso = 5
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj.charAt(i)) * peso
    peso--
    if (peso < 2) peso = 9
  }
  let digit = 11 - (sum % 11)
  if (digit > 9) digit = 0
  if (parseInt(cnpj.charAt(12)) !== digit) return false
  // Calcula o segundo dígito verificador
  sum = 0
  peso = 6
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj.charAt(i)) * peso
    peso--
    if (peso < 2) peso = 9
  }
  digit = 11 - (sum % 11)
  if (digit > 9) digit = 0
  if (parseInt(cnpj.charAt(13)) !== digit) return false

  return true
}

/**
 * Valida um CNPJ.
 *
 * Esta função verifica se um CNPJ é válido. Um CNPJ válido deve ter 14 dígitos
 * e passar no teste dos dois dígitos verificadores.
 *
 * @param {string} cnpj O CNPJ a ser validado.
 * @returns {boolean} Retorna `true` se o CNPJ for válido, caso contrário retorna `false`.
 */
export function validaCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '') // Remove caracteres não numéricos

  if (cnpj.length !== 14) {
    return false
  }

  // Elimina CNPJs invalidos conhecidos
  if (/^(\d)\1+$/.test(cnpj)) {
    return false
  }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(0)) {
    return false
  }

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  return resultado == digitos.charAt(1)
}
/**
 * Valida um número de RG (Registro Geral) brasileiro.
 *
 * Esta função verifica se um número de RG é válido. Um RG válido deve ter 9 dígitos
 * e passar no teste do dígito verificador.
 *
 * @param {string} rg O número de RG a ser validado.
 * @returns {boolean} Retorna `true` se o RG for válido, caso contrário retorna `false`.
 */
export function validaRG(rg) {
  if (!rg) return false
  rg = rg.replace(/[^\d]+/g, '')

  if (rg === '' || rg.length < 8 || rg.length > 9) return false

  // Elimina RGs inválidos conhecidos
  if (/^(0{8,9}|1{8,9}|2{8,9}|3{8,9}|4{8,9}|5{8,9}|6{8,9}|7{8,9}|8{8,9}|9{8,9})$/.test(rg)) {
    return false
  }

  // Validação do dígito verificador para RGs de 9 dígitos
  if (rg.length === 9) {
    let soma = 0
    for (let i = 0; i < 8; i++) {
      soma += parseInt(rg.charAt(i), 10) * (9 - i)
    }
    let digito = soma % 11
    if (digito === 10) digito = 'X' // Alguns estados usam 'X' como dígito verificador
    else digito = digito.toString()

    if (digito !== rg.charAt(8)) return false
  }

  // Para RGs de 8 dígitos, a validação específica do estado seria necessária
  // Aqui, estamos apenas verificando o comprimento e a não repetição de dígitos

  return true
}

/**
 * Função para formatar um valor numérico em formato de moeda brasileira (Real - BRL).
 *
 * @param {number} value - O valor numérico a ser formatado.
 * @returns {string} Uma string formatada no formato de moeda brasileira.
 */
export function formatarValor(value) {
  // A função utiliza o construtor Intl.NumberFormat para criar um objeto
  // que realizará a formatação do valor numérico no formato de moeda brasileira.
  // O primeiro parâmetro é o código da localidade (pt-BR para português do Brasil).
  // O segundo parâmetro é um objeto que define as opções de formatação, neste caso:
  //  - style: "currency" indica que queremos formatar como moeda.
  //  - currency: "BRL" indica que queremos utilizar o Real brasileiro como moeda.
  if (String(value).includes('R$')) return value
  const formattedValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)

  // Retorna o valor formatado em formato de string de moeda brasileira.
  return formattedValue
}

/**
 *
 * @param {*} value Formata o valor tipo number para a string com '%'
 * @returns Valor formatado com % e formato '##,##%'
 */
export function formatarValorPorcentagem(value) {
  if (typeof value === 'number') {
    value = value.toFixed(2)
  } else if (typeof value === 'string') {
    value = parseFloat(value).toFixed(2)
  } else {
    return 'Invalid input'
  }

  return value.replace('.', ',') + '%'
}

/**
 * Função que formata a data para o padrao brasileiro
 * @param {string} date Exemplo "1998-03-15"
 * @returns Exemplo '15/03/1998'
 */
export function formatarData(date) {
  try {
    if (!date) {
      return ''
    }

    if (typeof date !== 'string' && typeof date !== 'Date') return ''

    date = String(date)
    if (date.includes('/')) return date
    return new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(new Date(date))
  } catch (error) {
    console.error('Erro ao converter a data: ' + date + '\n Erro: ' + error)
  }
}

/**

 * Pega o ano e o mês atual nesse formato "202305".

 * @param {Date} data - Data a ser formatada

 * @returns {string} Retorna o resultado da concatenação.

 */

/**
 * Formata uma data do tipo Date para texto representando o mês e ano.
 * @param {Date} data - Objeto Date do JavaScript.
 * @returns {string} Texto representando o mês/ano. Exemplo: "Janeiro/2024".
 */
export function formatarCompetencia(data) {
  // Extrair o ano e o mês da data
  const ano = data.getFullYear()
  const mes = data.getMonth()

  // Array com o nome dos meses
  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  // Construir o texto formatado
  const textoMesAno = meses[mes] + '/' + ano

  return textoMesAno
}

/**
 * Função que formata a data e hora para o padrao brasileiro
 * @param {string} date Exemplo "1998-03-15"
 * @returns Exemplo '15/03/1998 15:34'
 */
export function formatarDataHora(date) {
  date = String(date)
  if (date.includes('/')) return date

  const options = {
    timeZone: 'America/Cuiaba',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date))
}

export function formatarAposentadoInativo(cat) {
  if (!cat) return null
  return cat
}

export function formatarValorReais(valor) {
  if (!valor) return null
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor)
}

export function formatarCompetenciaDescricao(competencia) {
  competencia = String(competencia)
  const ano = competencia.slice(0, 4)
  const mes = competencia.slice(4)
  let mesStr = ''
  switch (mes) {
    case '01':
      mesStr = 'Janeiro'
      break
    case '02':
      mesStr = 'Fevereiro'
      break
    case '03':
      mesStr = 'Março'
      break
    case '04':
      mesStr = 'Abril'
      break
    case '05':
      mesStr = 'Maio'
      break
    case '06':
      mesStr = 'Junho'
      break
    case '07':
      mesStr = 'Julho'
      break
    case '08':
      mesStr = 'Agosto'
      break
    case '09':
      mesStr = 'Setembro'
      break
    case '10':
      mesStr = 'Outubro'
      break
    case '11':
      mesStr = 'Novembro'
      break
    case '12':
      mesStr = 'Dezembro'
      break
    default:
      mesStr = 'Mês inválido'
      break
  }

  return `${mesStr}/${ano}`
}

/**
 * Função que formata a data para o padrao global
 * @param {string} date Exemplo '15/03/1998 00:00'
 * @returns {Date} Exemplo "1998-03-15 00:00"
 */
export function formatarDataGlobal(date) {
  try {
    // Caso já esteja vazio
    if (!date) return null

    // Caso já esteja formatado
    if (isValid(date)) return new Date(date)

    // Caso já esteja formatado mas em string (ex: '1998-03-15')
    if (isValid(new Date(date))) return new Date(date)

    let formattedDate = parse(date, 'dd/MM/yyyy', new Date())

    return formattedDate
  } catch (error) {
    console.error(error)
  }
}

/**
 * Função que formata os códigos sequenciais
 * @param {Number} codigo Exemplo '15/03/1998'
 * @returns {string} Exemplo "1998-03-15"
 */
export function formatarCodigoSequencial(codigo) {
  return codigo?.toString().padStart(6, '0')
}

export function formatarParseFloat(valor) {
  return parseFloat(valor.replace(/[.]/g, '').replace(/[,]/g, '.'))
}

export function formatarToLocaleString(valor) {
  return valor.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function formatarDataWTime(date) {
  return `${new Date(date).toLocaleDateString('pt-BR', { dateStyle: 'long' })} ${new Date(date).toLocaleTimeString('pt-BR')}`
}

export function formatarDataCurto(data) {
  if (!data) return ''

  const date = new Date(date)

  if (date == 'Invalid Date') return ''

  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export function messages(toast) {
  return {
    error: (text) => msgError(text, toast),
    success: (text) => msgSuccess(text, toast),
    info: (text) => msgInfo(text, toast),
    warning: (text) => msgWarning(text, toast),
  }
}

export function getNextMonthAndYear() {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1 // Os meses são baseados em zero, então adicionamos 1
  const currentYear = currentDate.getFullYear()

  // Adicionar 1 mês
  let nextMonth = currentMonth + 1
  let nextYear = currentYear

  if (nextMonth > 12) {
    nextMonth = 1 // Se ultrapassar 12, voltamos para janeiro
    nextYear++ // E incrementamos o ano
  }

  return { nextMonth, nextYear }
}

export function msgWarning(Text, toast) {
  toast(
    {
      component: ToastificationContent,
      props: {
        title: 'Alerta!',
        text: Text,
        icon: 'BellIcon',
        variant: 'warning',
      },
    },
    {
      position: 'top-right',
      timeout: 8000,
    },
  )
}

export function msgInfo(Text, toast) {
  toast(
    {
      component: ToastificationContent,
      props: {
        title: 'Informação...',
        text: Text,
        icon: 'InfoIcon',
        variant: 'info',
      },
    },
    {
      position: 'top-right',
      timeout: 8000,
    },
  )
}

export function msgError(Text, toast) {
  toast(
    {
      component: ToastificationContent,
      props: {
        title: 'Atenção!',
        text: Text,
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    },
    {
      position: 'top-right',
      timeout: 8000,
    },
  )
}

export function msgSuccess(Text, toast) {
  toast(
    {
      component: ToastificationContent,
      props: {
        title: 'Sucesso!',
        text: Text,
        icon: 'UserCheckIcon',
        variant: 'success',
      },
    },
    {
      position: 'top-right',
      timeout: 8000,
    },
  )
}

/**
 * Verifica se uma data é válida
 * @param {Data} Date
 * @returns True or False
 */
export function validarData(data) {
  if (data) data = formatarData(data)

  // Verificar se o formato da data está correto (dd/mm/aaaa)
  const regexData = /^\d{2}\/\d{2}\/\d{4}$/
  if (!regexData.test(data)) {
    return false
  }

  // Extrair o dia, mês e ano da data
  const [dia, mes, ano] = data.split('/').map(Number)

  // Verificar se o dia, mês e ano estão dentro dos intervalos corretos
  const dataObj = new Date(ano, mes - 1, dia)
  return dataObj.getDate() === dia && dataObj.getMonth() === mes - 1 && dataObj.getFullYear() === ano
}

/**
 * Converte um valor para o tipo number.
 * @param {string|number|null|undefined} entrada - O valor a ser convertido para o tipo number. Pode ser uma string contendo um número com ou sem separadores ('.' ou ','), um símbolo de porcentagem ('%'), 'R$', ou um número, além de poder ser também `null` ou `undefined`.
 * @returns {number|null|undefined} O valor convertido para o tipo number, `null` se o valor não puder ser convertido ou a entrada for uma string sem dígitos numéricos, `undefined` se a entrada for `undefined`.
 */
export function formatarParaNumber(entrada) {
  if (entrada === null) {
    return null
  }

  if (entrada === undefined) {
    return undefined
  }

  if (!isNaN(Number(entrada))) {
    return Number(entrada)
  }

  if (typeof entrada === 'string') {
    // Remove caracteres não-dígitos exceto ',', '.', e '%'
    let valorLimpo = entrada.replace(/[^\d,.-]/g, '')

    // Verifica se a string é vazia após a remoção, o que indica que não havia números
    if (valorLimpo === '') {
      return null
    }

    // Substitui ',' por '.' para o formato correto em JavaScript
    valorLimpo = valorLimpo.replace(/\./g, '')
    // Substitui ',' por '.' para o formato correto em JavaScript
    valorLimpo = valorLimpo.replace(/,/g, '.')

    // Remove o símbolo de porcentagem e divide por 100 se necessário
    if (valorLimpo.includes('%')) {
      valorLimpo = valorLimpo.replace(/%/g, '')
      return parseFloat(valorLimpo)
    } else return Number(valorLimpo)
  }
}

export function detectarNavegador() {
  const agenteUsuario = navigator.userAgent
  let navegador

  // Verifica se o navegador é o Chrome
  if (agenteUsuario.indexOf('Chrome') !== -1 && agenteUsuario.indexOf('Safari') !== -1) {
    navegador = 'Google Chrome'
  }
  // Verifica se o navegador é o Safari
  else if (agenteUsuario.indexOf('Safari') !== -1 && agenteUsuario.indexOf('Chrome') === -1) {
    navegador = 'Safari'
  }
  // Verifica se o navegador é o Firefox
  else if (agenteUsuario.indexOf('Firefox') !== -1) {
    navegador = 'Firefox'
  }
  // Verifica se o navegador é o Microsoft Edge
  else if (agenteUsuario.indexOf('Edg') !== -1) {
    navegador = 'Microsoft Edge'
  }
  // Verifica se o navegador é o Internet Explorer
  else if (agenteUsuario.indexOf('MSIE') !== -1 || agenteUsuario.indexOf('Trident') !== -1) {
    navegador = 'Internet Explorer'
  }
  // Caso não seja possível identificar o navegador, retorna "Desconhecido"
  else {
    navegador = 'Desconhecido'
  }

  return navegador
}

/**
 * Converte uma string e devolve um cpf formatado.
 * @param {string} separador - Caracter para inserir formatação. Exemplo: '00123456789'
 * @returns {string} Retorna o resultado da concatenação.
 */
export function formatarCPF(cpf) {
  const regexCPF = /^(\d{3})(\d{3})(\d{3})(\d{2})$/
  return cpf.replace(regexCPF, '$1.$2.$3-$4')
}

export function censurarCPF(cpf) {
  const formatado = formatarCPF(cpf)
  return '***' + formatado.substring(3, cpf.length - 2) + '**'
}

export function formatarTipoDate(data) {
  const dataFormatada = formatarData(data)
  const dataLista = dataFormatada.split('/')
  return new Date(dataLista[2], Number(dataLista[1]) - 1, dataLista[0])
}

/**
 * Verifica se um objeto está vazio.
 *
 * @param {Object} objeto - O objeto a ser verificado.
 * @returns {boolean} Retorna true se o objeto estiver vazio, caso contrário, retorna false.
 */
export function isNullObject(objeto) {
  // Verifica se o objeto é nulo ou indefinido.
  if (objeto === null || objeto === undefined) {
    return true
  }

  // Verifica se o objeto não tem propriedades próprias enumeráveis.
  for (const chave in objeto) {
    if (objeto.hasOwnProperty(chave)) {
      return false
    }
  }

  return true
}

/**
 * Calcula o número de dias de diferença entre duas datas.
 *
 * @param {string} dataInicio - A primeira data no formato de string.
 * @param {string} dataFim - A segunda data no formato de string.
 * @returns {number} Número de dias de diferença entre as duas datas.
 *
 * @example
 * // Retorna 31
 * calcularDiasEntreDatas("2023-01-01", "2023-02-01");
 *
 * @example
 * // Retorna 365
 * calcularDiasEntreDatas("2023-01-01", "2024-01-01");
 */
export function calcularDiasEntreDatas(dataInicio, dataFim) {
  const inicio = startOfDay(new Date(dataInicio))
  const fim = endOfDay(new Date(dataFim))

  return differenceInDays(fim, inicio)
}

/**
 * Converte um texto em PascalCase, onde a primeira letra de cada palavra é maiúscula
 * e as palavras são separadas por espaços.
 *
 * @param {string} texto - O texto a ser convertido.
 * @returns {string} O texto convertido em PascalCase com espaços.
 */
export function PascalCaseComEspaco(texto) {
  // Divide o texto em palavras usando espaço como separador
  const palavras = String(texto).split(' ')

  // Capitaliza a primeira letra de cada palavra e junta novamente as palavras
  const resultado = palavras
    .map((palavra) => {
      // Verifica se a palavra está em letras minúsculas
      if (palavra.length > 0) {
        return palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase()
      } else {
        return palavra // Mantém palavras vazias como estão
      }
    })
    .join(' ')

  return resultado
}

/**
 * Remove acentuações de uma string.
 *
 * @param {string} texto - A string da qual deseja remover os acentos.
 * @returns {string} Uma nova string com os acentos removidos.
 *
 * @example
 * const textoComAcentos = "Coração café açúcar";
 * const textoSemAcentos = removerAcentos(textoComAcentos);
 */
export function removerAcentos(texto) {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
 * Rounds a number up from the third decimal place to two decimal places.
 *
 * @param num - The number to be rounded.
 * @returns The number rounded to two decimal places.
 */
export function roundUp(num) {
  const DECIMAL_SHIFT = 100
  const DECIMAL_PLACES = 2

  if (typeof num !== 'number') {
    throw new Error('Input must be a number')
  }

  const rounded = Math.ceil(num * DECIMAL_SHIFT) / DECIMAL_SHIFT
  return parseFloat(rounded.toFixed(DECIMAL_PLACES))
}

/**
 * Rounds the given value to two decimal places if necessary.
 * @param {number} valor - The value to be rounded.
 * @returns {number} - The rounded value.
 */
export function arrendondarCalculo(valor) {
  // Separa a parte inteira da parte decimal
  let partes = valor.toString().split('.')
  let parteInteira = partes[0]
  let parteDecimal = partes.length > 1 ? partes[1] : ''

  // Se a parte decimal tiver menos de 3 dígitos, retorna o valor original
  if (parteDecimal.length < 3) {
    return Number(valor.toFixed(2))
  }

  // Verifica a terceira casa decimal
  let terceiraCasa = parseInt(parteDecimal.charAt(2), 10)

  if (terceiraCasa > 0) {
    // Se a terceira casa for maior que 0, aumenta a segunda casa decimal em 1
    let valorAjustado = Number(valor) + 0.01
    // Retorna o valor ajustado arredondado para 2 casas decimais
    return Number(valorAjustado.toFixed(2))
  } else {
    // Se a terceira casa não for maior que 0, retorna o valor original arredondado para 2 casas decimais
    return Number(valor.toFixed(2))
  }
}

/**
 * Calcula a idade com base na data de nascimento fornecida.
 * @param {string} dataNascimento - A data de nascimento no formato 'YYYY-MM-DD'.
 * @returns {number} A idade calculada.
 */
export function calcularIdade(dataNascimento) {
  const dataAtual = new Date()
  const dataNascimentoFormatada = new Date(dataNascimento)

  let idade = dataAtual.getFullYear() - dataNascimentoFormatada.getFullYear()

  // Verifica se a data de aniversário já ocorreu no ano atual
  const mesAtual = dataAtual.getMonth()
  const diaAtual = dataAtual.getDate()
  const mesNascimento = dataNascimentoFormatada.getMonth()
  const diaNascimento = dataNascimentoFormatada.getDate()

  if (mesAtual < mesNascimento || (mesAtual === mesNascimento && diaAtual < diaNascimento)) {
    idade--
  }

  return idade
}

export function formatarToStringComPonto(numero) {
  if (!numero) {
    return 0.0
  }
  return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export function primeiroNome(nome) {
  return nome.split(' ')[0]
}

/**
 * Formata um valor para um número de ponto flutuante.
 * Se o valor de entrada não for um número válido, um erro é lançado.
 *
 * @param {number | string | null | undefined} num - O valor a ser formatado.
 * @param {number} [val=2] - O número de casas decimais que devem ser mantidas.
 * @returns {number | null} O número formatado ou null se o valor de entrada for inválido.
 */
export function formatarToParseFloat(num, val = 2) {
  if (num === undefined || num === null || isNaN(num)) return

  return parseFloat(num).toFixed(val)
}
